<template>
  <VuePerfectScrollbar class="e-sidebar" tagname="aside">
    <BackButton v-if="showBackButton || showShortBackButton" />
    <template v-if="!gdprConfirmationNeeded">
      <div v-if="userInfo && userInfo.id" class="e-sidebar__user">
        {{ $t('system.hello') }}, <strong>{{ userInfo.username }}</strong>
      </div>
    </template>

    <MessageNav v-if="userInfo && userInfo.id" />
    <ChatNav v-if="userInfo && userInfo.id" />
    <CounselingEventNav
      v-if="userInfo && userInfo.id && counselingEventsEnabled"
    />
    <AccountNav v-if="userInfo && userInfo.id" />
  </VuePerfectScrollbar>
</template>

<script>
import { mapGetters } from 'vuex'

import AccountNav from 'atoms/Accountnav/Accountnav'
import BackButton from 'atoms/BackButton/BackButton'
import MessageNav from 'atoms/Messagenav/Messagenav'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatNav from '../../atoms/ChatNav/ChatNav.vue'
import CounselingEventNav from '../../atoms/CounselingEventNav/CounselingEventNav.vue'

export default {
  name: 'TheSidebar',
  components: {
    AccountNav,
    BackButton,
    MessageNav,
    VuePerfectScrollbar,
    ChatNav,
    CounselingEventNav
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'currentRouteName',
      'gdprConfirmationNeeded',
      'counselingEventsEnabled'
    ]),

    showBackButton() {
      return (
        !this.userInfo &&
        (this.currentRouteName === 'content' ||
          this.currentRouteName === 'support')
      )
    },
    showShortBackButton() {
      return this.gdprConfirmationNeeded && this.currentRouteName !== 'gdpr'
    }
  }
}
</script>

<style lang="scss">
@import '_assets/sidebar';
</style>
